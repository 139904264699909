<template>
    <div>
        <div :class="`${ 'mine'} card-cedis-local position-relative border br-8 wf-145px hm-185px d-flex flex-column justify-content-around`">
            <div class="text-center px-2">
                <!-- <div v-if="cedis.stock === 1 || cedis.stock === 2" class="ind-stock wh-32 rounded-circle d-middle-center" :class="`${cedis.stock === 2 ? 'bg-general2' : 'bg-green'}`">
                <i :class="`${cedis.stock === 2 ? 'icon-package-variant-closed' : 'icon-package-variant'} text-white f-24`" />
            </div> -->
                <div>
                    <p class="text-general mt-1 wM-120px ml-2">{{ _.get(dataAdicional, 'cedis_destino_nombre', cedis.nombre) }}</p>
                </div>
                <div class="d-middle-center tres-puntos my-3">
                    <i class="icon-format-list-checks text-general f-20" />
                    <p v-if="(tipo === 2 && cedis.cantidad != null) || (tipo === 1 && dataAdicional && dataAdicional.cantidad != null)"
                       class="f-17 fr-light ml-2 text-general"
                    >
                        {{ redondearNumero((tipo === 2 ? cedis.cantidad : dataAdicional.cantidad) || 0) }} Und.
                    </p>
                    <p v-else class="f-17 fr-light ml-2 text-general">0</p>
                </div>
                <div v-if="tipo === 2" class="d-middle-center gap-2">
                    <el-tooltip content="Confirmar" effect="light" :visible-arrow="false">
                        <button class="d-middle-center border btn p-0 f-14 br-8 wh-32 bg-green" @click="$refs.modalAprobar.toggle()">
                            <i class="icon-ok-circled-outline text-white" />
                        </button>
                    </el-tooltip>
                    <el-tooltip content="Rechazar" effect="light" :visible-arrow="false">
                        <button class="d-middle-center border btn p-0 text-alert f-14 br-8 wh-32 bg-alert" @click="updateEstadoTraslado(2)">
                            <i class="icon-cancel-outline text-white" />
                        </button>
                    </el-tooltip>
                    <el-tooltip content="Anular" effect="light" :visible-arrow="false">
                        <button class="d-middle-center border btn p-0 text-general f-14 br-8 wh-32 bg-whitesmoke3" @click="deleteTraslado">
                            <i class="icon-trash-empty" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-else>
                    <p class="text-general mt-1 wM-120px ml-2 f-13 br-10 px-1" :class="`${ estados[dataAdicional.estado_origen].clase }`">{{ estados[dataAdicional.estado_origen].label }}</p>
                    <button v-if="dataAdicional.estado_origen == 0" class="d-middle-center border btn text-general f-14 br-8 px-2 py-0 mt-1 bg-whitesmoke3" @click="deleteTraslado">
                        <i class="icon-trash-empty" /> Anular
                    </button>
                </div>
            </div>
        </div>
        <modalSiNo
        ref="modalAprobar"
        titulo="Trasladar Stock"
        mensaje="¿Desea confirmar el traslado del stock?"
        icon="close"
        @accion="aprobarTrasladoStock"
        />
    </div>
</template>

<script>
import Traslados from '~/services/cedis/supercedis_traslados'

export default {
    props: {
        cedis: {
            type: Object,
            required: false,
            default: () => { }
        },
        dataAdicional: {
            type: Object,
            required: false,
            default: () => { }
        },
        tipo: {
            type: Number,
            required: true,
            default: 1
        },
    },
    data(){
        return {
            idCedi: parseInt(this.$route.params.id_cedis),
            estados: {
                0:{label:'Pendiente', clase:'bg-warning text-black2',  bg:'bg-general2'},
                1:{label: 'Confirmado', clase:'bg-green text-white',  bg:'bg-green'},
                2:{label: 'Rechazado', clase:'bg-danger text-white',  bg:'bg-alert'}
            }
        }
    },
    methods: {
        async updateEstadoTraslado(estado){
            try {
                const payload = {
                    id: this.dataAdicional.id,
                    estado
                }
                const { data } = await Traslados.updateEstadoTraslado(payload);
                this.notificacion('Éxito', data.mensaje, "success")
                this.$emit('entregado')
                if(this.estado != 1){
                    this.dataAdicional.estado_destino = 1;
                    this.dataAdicional.user_recibio = data.data;
                }else{
                }
            } catch (error){
                return this.error_catch(error);
            }
        },
        async deleteTraslado(){
            try {
                const { data } = await Traslados.cancelarTraslado(this.dataAdicional.id)
                this.$emit('entregado')
            } catch (error){
                return this.error_catch(error)
            }
        },
        async aprobarTrasladoStock(res){
            if(!res){
                this.$refs.modalAprobar.toggle()
                return
            }
            try {
                const payload = {
                    id: this.dataAdicional.id,
                }
                const { data } = await Traslados.aprobarTrasladoStock(payload);
                this.notificacion('Éxito', 'Traslado de stock aprobado correctamente', "success")
                this.$emit('entregado')

            } catch (error){
                return this.error_catch(error);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card-cedis-local {
	.ind-stock {
		top: -14px;
		right: -17px;
	}

	.btn-recibir {
		background-color: #28D07B;
	}

	&.mine {
		background: #F6F9FB;
		border: 1px solid transparent !important
	}

	.icon-ok-circled-outline {
		color: #28D07B;
	}
}
</style>
