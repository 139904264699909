import axios from 'axios'

const api = "super-cedis"

const ENDPOINTS = {

    getSolicitudesTraslado(idCedis){
        return axios.get(`${api}/${idCedis}/traslados-stock`)
    },
    getHistoriaslTraslado(idCedis, params){
        return axios.get(`${api}/${idCedis}/traslados-stock/historial`, { params })
    },
    updateEstadoTraslado(payload){
        return axios.put(`${api}/traslados-stock/update-estado`, payload)
    },
    cancelarTraslado(id){
        return axios.delete(`${api}/traslados/${id}/cancelar`)
    },
    aprobarTrasladoStock(payload){
        return axios.put(`${api}/traslados-stock/aprobar`, payload)
    },
}

export default ENDPOINTS;
