<template>
    <section class="sec-super-cedis scroll-none">
        <!--  -->
        <div class="info-top bg-white p-3 br-8 my-3">
            <div class="d-middle">
                <div class="d-middle">
                    <p class="f-18 text-general fr-medium">Supercedis</p>
                    <el-tooltip placement="right" effect="light" popper-class="w-350px p-2">
                        <p slot="content" class="f-12 text-general2 lh-15">
                            Cedis agregados por prioridad a los cuales
                            podrás solicitar unidades de productos de su stock cuando no se dispongan de las suficientes
                            requeridas en un pedido
                        </p>
                        <i class="icon-help-circle-outline text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="d-middle ml-auto">
                    <i class="icon-cedis-1 f-20 text-general" />
                    <el-select v-model="value" placeholder="Seleccionar Cedis" size="small">
                        <el-option v-for="item in options" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                    <el-tooltip content="Agregar" effect="light">
                        <div class="wh-32 br-8 bg-general d-middle-center text-white ml-3 mr-3 cr-pointer"
                             @click="nuevoCedisHijo"
                        >
                            <i class="icon-plus f-18" />
                        </div>
                    </el-tooltip>
                    <button class="btn w-100px border f-14 br-8" @click="openModalOrderSuperCedis">
                        Ordenar
                    </button>
                </div>
            </div>
            <div class="list-cedis overflow-auto scroll-none d-middle mx-0 mt-4">
                <div v-for="(data, index) in superCedisList" :key="index" class="col-auto">
                    <card-super-cedis
                    :cedis="data"
                    :index="index"
                    @deleteSuperCedis="openModalDeleteSuperCedis(data.id_cedis_hijo)"
                    />
                </div>
            </div>
        </div>
        <!-- solicitud cambio -->
        <div class="row mx-0">
            <div class="col-auto my-auto">
                <p class="f-18 fr-medium text-general">Solicitudes de traslado del stock</p>
            </div>
            <div class="col-auto my-auto d-middle ml-lg-auto text-general">
                <i class="icon-format-list-checks f-19 mr-2" />
                <p class="f-15 fr-light">Unidades solicitadas</p>
            </div>
            <div class="col-auto my-auto d-middle">
                <div class="d-middle-center wh-32 rounded-circle bg-general2">
                    <i class="icon-package-variant-closed text-white f-19" />
                </div>
                <p class="text-general2 fr-ligh f-15 ml-2">Stock reservado</p>
            </div>
            <div class="col-auto my-auto d-middle">
                <div class="d-middle-center wh-32 rounded-circle bg-green">
                    <i class="icon-package-variant text-white f-19" />
                </div>
                <p class="text-green fr-ligh f-15 ml-2">Stock disponible</p>
            </div>
        </div>
        <!-- listado solicitudes -->
        <div v-for="(data, index) in trasladosStock" :key="index" class="bg-white p-3 br-8 my-3">
            <div class="row">
                <div class="col-4">
                    <div class="d-flex my-2">
                        <i class="icon-pedidos text-general f-18" />
                        <div class="ml-2">
                            <p class="f-16 fr-medium text-general">Pedido Admin No. {{ data.id_admin_pedido }}</p>
                            <p v-show="data.id_pedido" class="f-16 fr-medium text-general">Pedido Cliente No. {{ data.id_pedido }}</p>
                            <p class="f-16 fr-light text-general2">{{ formatearFecha(data.fecha_entrega) }}</p>
                        </div>
                    </div>
                    <div class="d-middle my-2">
                        <i class="icon-package-variant-closed text-general f-20" />
                        <p class="f-16 fr-light ml-2 text-general">{{ data.barcode }} - {{ data.nombre }}</p>
                    </div>
                    <div class="d-middle my-2">
                        <i class="icon-cliente text-general f-20" />
                        <p class="f-16 fr-light ml-2 text-general">{{ data.cliente_nombre }}</p>
                    </div>
                    <div class="d-middle my-2">
                        <i class="icon-format-list-checks text-general f-20" />
                        <p v-if="data.cedis_hijos_solicitantes" class="f-16 fr-light ml-2 text-general">{{ redondearNumero(data.cantidad) }} unidades solicitadas</p>
                        <p v-else class="f-16 fr-light ml-2 text-general">{{ redondearNumero(calcularCantidades(data)) }} unidades solicitadas</p>
                    </div>
                    <div v-if="data.cedis_destino_nombre" class="d-middle my-2">
                        <i class="icon-map-marker-outline text-general f-20" />
                        <p class="f-16 fr-light ml-2 text-general">{{ data.direccion }}</p>
                    </div>
                </div>
                <div class="col-8 d-flex">
                    <div v-if="data.cedis_hijos_solicitantes" class="list-cedis-local d-flex overflow-auto scroll-none d-middle">
                        <card-cedis-local
                        :cedis="data"
                        :tipo="2"
                        :data-adicional="data"
                        @entregado="getTrasladoStock"
                        />
                    </div>
                    <div v-else class="">
                        <div v-for="(item, idx) in superCedisList" :key="idx" class="list-cedis-local d-flex overflow-auto scroll-none d-middle">
                            <card-cedis-local
                            v-if="data.solicitudes.find((e) => e.id_cedis_origen == item.id)"
                            :cedis="item"
                            :data-adicional="data.solicitudes.find((e) => e.id_cedis_origen == item.id)"
                            :tipo="1"
                            @entregado="getTrasladoStock"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalEliminar ref="refModalDeleteSuperCedis" titulo="Eliminar supercedis"
                       mensaje="¿Realmente desea eliminar este Cedis? No podrá solicitarle unidades de productos"
                       @eliminar="removerCedisHijo"
        />
        <modalEliminar ref="refModalDeleteSolicitudTraslado" titulo="Cancelar pedido"
                       mensaje="¿Realmente desea cancelar este pedido?"
                       @eliminar="cancelarSolicitud"
        />
        <modal-order-super-cedis ref="refModalOrderSuperCedis" :super-cedis-list="superCedisList" />
    </section>
</template>
<script>
import cardSuperCedis from './components/cardSuperCedis'
import cardCedisLocal from './components/cardCedisLocal'
import modalOrderSuperCedis from './partials/modalOrderSuperCedis'
import SuperCedis from '~/services/cedis/super_cedis'
import Traslados from '~/services/cedis/supercedis_traslados'
export default {
    components: {
        cardSuperCedis,
        cardCedisLocal,
        modalOrderSuperCedis,
    },
    data(){
        return {
            idCedi: parseInt(this.$route.params.id_cedis),
            superCedisList: [],
            trasladosStock: [],
            dataCancelar: {},
            idHijo: null,
            options: [],
            value: null
        }
    },
    mounted(){
        this.getCedis(),
        this.getCedisSelect(),
        this.getTrasladoStock()
    },
    methods: {
        openModalOrderSuperCedis(){
            this.$refs.refModalOrderSuperCedis.toggle()
        },
        openModalDeleteSuperCedis(id){
            this.idHijo = id
            this.$refs.refModalDeleteSuperCedis.toggle()
        },
        openModalCancelarPedido(data){
            console.log(data);
            this.dataCancelar = data
            this.$refs.refModalDeleteSolicitudTraslado.toggle()
        },
        mapData(data, idCedis){
            this.superCedisList.push(data);
            const idx = this.options.findIndex((e) => e.id == idCedis)
            this.options.splice(idx, 1)
        },
        mapDataRemove(id){
            const idx = this.superCedisList.findIndex((e) => e.id_cedis_hijo == id);
            this.options.push(this.superCedisList[idx]);
            this.superCedisList.splice(idx, 1)
        },
        calcularCantidades(item){
            let sum = 0
            if(item.solicitudes){
                item.solicitudes.forEach(element => {
                    if(element.cantidad != null) sum += parseInt(element.cantidad);
                });
            }else{
                item.cedis_hijos_solicitantes.forEach(element => {
                    if(element.cantidad != null) sum += parseInt(element.cantidad);
                });
            }
            return sum;
        },
        async getCedis(){
            try {
                const { data } = await SuperCedis.getCedisHijos(this.idCedi)
                this.superCedisList = data.data
            } catch (error){
                return this.error_catch(error);
            }
        },
        async getCedisSelect(){
            try {
                const params = {
                    idCedis: this.idCedi
                }
                const { data } = await SuperCedis.getCedisSelect(params)
                this.options = data.data
            } catch (error){
                return this.error_catch(error);
            }
        },
        async nuevoCedisHijo(){
            try {
                if (this.value == null) return this.notificacion('Warning', 'Por favor seleccione un cedis', 'warning')
                const payload = {
                    idCedis: this.idCedi,
                    cedisHijo: this.value,
                }
                const { data } = await SuperCedis.agregarCedisHijo(payload)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.value = null;
                this.mapData(data.data, payload.cedisHijo);
            } catch (error){
                return this.error_catch(error);
            }
        },
        async removerCedisHijo(){
            try {
                const { data } = await SuperCedis.removeCedisHijo(this.idHijo)
                this.$refs.refModalDeleteSuperCedis.toggle()
                this.notificacion('Éxito', data.mensaje, 'success')
                this.mapDataRemove(this.idHijo)
                this.idHijo = null
            } catch (error){
                return this.error_catch(error);
            }
        },
        async getTrasladoStock(){
            try {
                const { data } = await Traslados.getSolicitudesTraslado(this.idCedi)
                this.trasladosStock = data.data;
            } catch (error){
                return this.error_catch(error);
            }
        },
        async cancelarSolicitud(){
            try {
                const { data } = await Traslados.cancelarTraslado(this.dataCancelar);
                this.notificacion('Éxito', data.mensaje, "success")
                this.dataCancelar = {}
                this.$refs.refModalDeleteSolicitudTraslado.toggle()
                this.getTrasladoStock();
            } catch (error){
                return this.error_catch(error);
            }

        },
        mapCedisTraslados(item, data){
            const tempData = item.solicitudes.find((e) => e.id_cedis_origen == data.id)
            const objTemp = data;
            if (tempData){
                if (tempData.id_admin_pedido == item.id_admin_pedido && tempData.idProducto == item.idProducto){
                    objTemp.estado = tempData.estado_destino
                    objTemp.cantidad = tempData.cantidad
                    objTemp.prueba = tempData
                }
            }
            return objTemp;
        }
    },
}
</script>
<style lang="scss">
.sec-super-cedis {
	overflow: auto;
	height: calc(100vh - 220px);

	.list-cedis-local {
		gap: 20px
	}
}
</style>
